import React from 'react'

import Meta from '../components/Meta'
import Layout from '../components/Layout'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const WhatWeDo = ({ data }) => {
  const content = data.prismicWhatWeDo.data

  return (
    <Layout>
      <Meta title="What We Do" />

      <header id="page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>What We Do</h1>
            </div>
          </div>
        </div>
      </header>

      <section className="container lead-in">
        <div className="row align-items-center">
          <div className="col-md-6 order-md-2">
            <Img
              fluid={content.featured_image.localFile.childImageSharp.fluid}
              alt={content.featured_image.alt}
            />
          </div>
          <div className="col-md-6 order-md-1">
            <h2>{content.intro}</h2>
          </div>
        </div>
      </section>

      <section className="container lead-in">
        <div className="row justify-content-between">
          <div
            className="col-md-7 philosophy"
            dangerouslySetInnerHTML={{ __html: content.philosophy.html }}
          ></div>
          <div className="col-md-4 fun-facts">
            {content.stats.map(stat => (
              <div className="stat" key={stat.id}>
                <h2 dangerouslySetInnerHTML={{ __html: stat.primary.metric }}></h2>
                <p>{stat.primary.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="container process expertise">
        <div className="row">
          <div className="col">
            <h4>Expertise</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <h3>Strategy</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: content.strategy_content.html,
              }}
            ></div>
          </div>
          <div className="col-md-4">
            <h3>Design</h3>
            <div
              dangerouslySetInnerHTML={{ __html: content.design_content.html }}
            ></div>
          </div>
          <div className="col-md-4">
            <h3>Technology</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: content.technology_content.html,
              }}
            ></div>
          </div>
        </div>
      </section>

      <section className="container collaborators">
        <div className="row">
          <div className="col-md-12">
            <h4>{content.collaborators_title}</h4>
          </div>
        </div>
        <div className="row d-flex justify-content-start">
          <div
            className="col-md-6 philosophy"
            dangerouslySetInnerHTML={{
              __html: content.collaborators_content.html,
            }}
          ></div>
          <div
            className="col-md-5"
            dangerouslySetInnerHTML={{
              __html: content.collaborators_list.html,
            }}
          ></div>
        </div>
      </section>

      <div className="container">
        <div className="row py-md-5">
          <div className="col">
            <AniLink
              to="/work"
              className="view--all"
              cover
              duration={1}
              bg="#b88343"
            >
              View our Case Studies
              <span className="icon-right-open" />
            </AniLink>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WhatWeDo

export const query = graphql`
  {
    prismicWhatWeDo {
      data {
        intro
        featured_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 570) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        philosophy {
          html
        }
        stats: body {
          id
          primary {
            label
            metric
          }
        }
        strategy_content {
          html
        }
        design_content {
          html
        }
        technology_content {
          html
        }
        collaborators_title
        collaborators_content {
          html
        }
        collaborators_list {
          html
        }
      }
    }
  }
`
